/* eslint-disable jsx-a11y/anchor-is-valid */
import { storage as LocalStorage } from "services/config/storage";
import React, { Component } from "react";
import Navbar from "components/Navbar";
import "./index.scss";
import WorksheetModal from "../../components/WorksheetModal/index";
import { Document, Page } from "react-pdf";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as worksheetActions from "../../store/worksheet/actions";

class Worksheet extends Component {
  state = {};

  constructor(props, context) {
    super(props, context);

    this.state = {
      announcementsModalVisible: false,
      selectedWorksheet: [],
      link: "",
      active: false,
    };
  }

  async componentDidMount() {
    {
      /*   if (!this.state.auth) {
      // this.props.history.push("/login");
      this.props.history.push("/onboarding", {
        urlToRedirect: this.props.location.pathname,
      });
    } else
    */
    }

    var selectedData = [];

    this.props.reduxData?.student?.batchDetails[0]?.batch_id &&
      (await this.props.getWorksheet(
        this.props?.reduxData?.student?.batchDetails[0]?.batch_id
      ));

    if (this.props?.reduxData?.worksheets?.worksheets?.data !== []) {
      if (this.props?.match?.params?.Id != null) {
        selectedData =
          this.props?.reduxData?.worksheets?.worksheets?.data?.filter(
            (singlesheet) =>
              singlesheet?.worksheet?.id == this.props?.match?.params?.Id
          );
      } else {
        selectedData =
          this.props?.reduxData?.worksheets?.worksheets?.data[0].worksheet;
      }
      console.log("selecteddata", selectedData);
      this.setState({ selectedWorksheet: selectedData[0] });
    }

    window.addEventListener("popstate", (e) => {
      //this.props.history.push("/");
    });

    let x = this.state?.selectedWorksheet?.worksheet?.link.split("/");
    x.pop();
    let link = x.join("/") + "/preview?usp=sharing";
    this.setState({ link: link });
  }

  openAnnouncementsModal = () => {
    this.setState({
      announcementsModalVisible: !this.state.announcementsModalVisible,
    });
  };

  handleRightClick = (worksheet) => {
    let x = worksheet?.worksheet?.link.split("/");
    x.pop();

    let link = x.join("/") + "/preview?usp=sharing";
    console.log("worksheet >>>>>>>>", worksheet);
    // event.preventDefault();
    this.setState({
      selectedWorksheet: worksheet,
      link: link,
      active: !this.state.active,
    });
    // var selectedSheet = document.getElementById(
    //   `worksheet_${worksheet?.worksheet?.id}`
    // );
    // console.log("selectedSheet >", selectedSheet);
    // selectedSheet.classList.add("selectedWorksheet");
    // this.forceUpdate();
  };

  render() {
    return (
      <div className="worksheetcontainer">
        <Navbar />
        <div>
          <p
            className="back-btn"
            onClick={() => this.props.history.push("/user")}
          >
            &lt; GO TO DASHBOARD
          </p>
        </div>

        <div className="row left">
          <div className="col-md-8 col-xs-12 left-col">
            <div className="wrapper ">
              {/*this.props?.reduxData?.worksheets?.worksheets?.data
                ?.filter(
                  (singlesheet) =>
                    singlesheet?.worksheet?.id == this.props?.match?.params?.Id
                )

                .map((singlesheets) => {
                  console.log(
                    "sheets",
                    this.props?.reduxData?.worksheets?.worksheets?.data
                  );
                  return (
                    <div>
                      <h5>{singlesheets?.worksheet?.title}</h5>
                      <h6>{singlesheets?.worksheet?.description}</h6>
                    </div>
                  );
                })*/}
              {this.state.selectedWorksheet !== [] && (
                <div className="Left-head">
                  <h5>{this.state?.selectedWorksheet?.worksheet?.title}</h5>
                  <h6>
                    {this.state?.selectedWorksheet?.worksheet?.description}
                  </h6>
                </div>
              )}

              <div className="inside-col">
                {/* 
                <div
                  style={{
                    width: "640px",
                    height: "480px",
                    position: "relative",
                  }}
                >
                  <embed
                    type="application/pdf"
                    src={this.state.link}
                    width="800"
                    height="500"
                    frameborder="0"
                    scrolling="no"
                    seamless=""
                  />
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      position: "absolute",
                      opacity: "0",
                      right: "0px",
                      top: "0px",
                    }}
                  >
                    &nbsp;
                  </div>
                </div>
              */}

                <iframe
                  height="760px"
                  width="100%"
                  title="this is unique"
                  src={this.state.link}
                  frameborder="0"
                  scrolling="no"
                  seamless=""
                  sandbox="allow-forms allow-pointer-lock allow-same-origin allow-scripts allow-top-navigation"
                ></iframe>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xs-12 ">
            <div className="right-col">
              <div className="heading-col">
                <h4 className="heading-4">More Worksheets</h4>
              </div>
              {this.props?.reduxData?.worksheets?.worksheets?.data?.map(
                (worksheet) => {
                  console.log(
                    "class compari >",
                    this.state.selectedWorksheet?.worksheet?.id,
                    worksheet?.worksheet.id
                  );
                  return (
                    <div
                      id={`worksheet_${worksheet?.worksheet.id}`}
                      onClick={() => this.handleRightClick(worksheet)}
                      className={
                        this.state.selectedWorksheet?.worksheet?.id ===
                        worksheet?.worksheet.id
                          ? "right-inside-col selectedWorksheet"
                          : "right-inside-col"
                      }
                    >
                      <div className="inner">
                        <h5 className="heading-5">
                          {worksheet?.worksheet?.title}
                          <button className="event-btn1">
                            {worksheet?.worksheet?.tags[0]}
                          </button>
                        </h5>

                        <h6 className="heading-6">
                          {worksheet?.worksheet?.description}
                        </h6>
                      </div>
                    </div>
                  );
                }
              )}

              {/* <button
                onClick={() => this.openAnnouncementsModal()}
                className=" ant-btn primary-btn banner-btn ant-btn-primary ant-btn-lg worksheet-button"
              >
                View More
              </button>

            */}

              <WorksheetModal
                //FREAD-1342
                sendCtEvents={this.sendCtEvents}
                //FREAD-1342
                reduxData={this.props.reduxData}
                visible={this.state.announcementsModalVisible}
                closeModal={() => {
                  this.setState({
                    announcementsModalVisible:
                      !this.state.announcementsModalVisible,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({ reduxData: { ...state } }),
  (dispatch) =>
    bindActionCreators(
      {
        ...worksheetActions,
      },
      dispatch
    )
)(Worksheet);
