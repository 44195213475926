import React, {Component} from "react";
import Navbar from "components/Navbar";
import "./index.scss";
import ProfileForm from "./ProfileForm/index";
import {connect} from 'react-redux';

class StudentProfile extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div
                className={["student-profile-form", this.props.appConfig?.showNavbar ? '' : 'zero-padding',
                !this.props.user?.navbarFlag ? '' : 'zero-padding'].join(' ')}>
                {/* //FREAD-1276 */}
                    {!this.props.user?.navbarFlag && <Navbar />}
                {/* //FREAD-1276 */}
                <ProfileForm title="Complete Your Profile"/>
            </div>
        );
    }
}

export default connect(
    ({appConfig,user}) => ({appConfig,user}),
    null
)(StudentProfile);
