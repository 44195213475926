import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./index.scss";
import {
  ChevronRight,
  EditPaperIcon,
  SyncIcon,
} from "../../../../../assets/image/svg";
import * as EVENT from "../../../../../utils/eventKeys";


const RemainingClassCard = ({ reduxData, ...props }) => {
  let upcomingClasses = reduxData.student?.classes?.upcoming?.count;
  let previousClasses = reduxData.student?.classes?.previous?.count;
  let percentage =
    (upcomingClasses / (upcomingClasses + previousClasses)) * 100 || undefined;
  const [allowRenewSubscription, setAllowRenewSubscription] = useState(false);

  useEffect(() => {
    if (upcomingClasses?.toString() && upcomingClasses <= 10) {
      setAllowRenewSubscription(true);
    } else {
      setAllowRenewSubscription(false);
    }
  }, [upcomingClasses, previousClasses]);

  useEffect(() => {
    if (!allowRenewSubscription) {
      animateProgressBar();
    }
  }, [allowRenewSubscription, upcomingClasses, previousClasses]);

  const animateProgressBar = useCallback(() => {
    if (!percentage) {
      return;
    }
    let el = document.getElementById("bar");
    if (!el) return;
    let i = 0;
    let intervalId;
    intervalId = setInterval(function () {
      if (i > percentage) {
        return clearInterval(intervalId);
      }
      el.style.width = `${i++}%`;
    }, 5);
  }, [percentage]);

  return (
    <div
      className={[
        "osahan-account-page-left shadow-sm bg-white rounded left-classes",
        allowRenewSubscription && "bg-danger",
      ].join(" ")}
    >
      <div className=" pt-3 pl-3 pr-3 pb-1">
        <div className="osahan-user flex flex-row justify-content-between">
          <div
            className={[
              "classes-container",
              allowRenewSubscription && "danger-text",
            ].join(" ")}
          >
            <div className="osahan-user-media message">
              <span>
                You have <p className={"class-count"}>{upcomingClasses || 0}</p>{" "}
                classes remaining
              </span>
              {allowRenewSubscription && (
                <span>If you haven't renewed yet, click here!</span>
              )}
            </div>
            {allowRenewSubscription ? (
              <a
                className={"renew-text"}
                //FREAD-1342
                id="FLV_RENEW"
                //FREAD-1342
                onClick={() =>{
                //FREAD-1342
                props.sendCtEvents(EVENT.FLIVE_RENEW_BUTTON);
                //FREAD-1342
                  window.open(
                    "https://stones2milestones.typeform.com/to/iOv1XMbN#source=webapp",
                    "_blank"
                  )}
                }
              >
                <p>RENEW SUBSCRIPTION</p>
                <ChevronRight />
              </a>
            ) : (
              <div className="classes-left-progress">
                <div className="progress">
                  <div id={"bar"} className="filled" />
                </div>
                <p className={"class-count"}>
                  <p className={"bold-letter"}>{upcomingClasses}</p>/
                  {upcomingClasses + previousClasses || ""}
                </p>
              </div>
            )}
          </div>
          <div className={"renew-container"}>
            {allowRenewSubscription && <SyncIcon className={"icon sync"} />}

            {allowRenewSubscription && (
              <>
                <div
                  className={[
                    "circle-1",
                    allowRenewSubscription && "bg-white",
                  ].join(" ")}
                />
                <div className="circle-2" />
                <div className="circle-3" />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(
  (state) => ({ reduxData: { ...state } }),
  null
)(RemainingClassCard);
