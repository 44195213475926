import React, { useState,useEffect } from 'react'
import {Input,List,Avatar} from 'antd'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from 'react-router-dom';
import {TeacherApiCalls} from '../../services/teacher';
import * as storyActions from "store/story/actions";
import * as teacherActions from "store/teacher/actions";
import './Navbar.scss'

function Teacher_Search({shwContentSrch,selectOption,gradeLevel,showSearchInput}) {
    const [shwCat, setShwCat] = useState(false);
    const [srchVal, setSrchVal] = useState('');
    const [type,setType] = useState('');
    const [types,setTypes] = useState([]);
    const [lists,setList] = useState([]);
    
    useEffect(()=>{
        getlists(srchVal,type,setList,gradeLevel);

    },[type,srchVal,gradeLevel])
    useEffect(()=>{
        getCount(srchVal,setTypes,setType,gradeLevel);

    },[gradeLevel])
    return (
        <>
            <div className="" style={{
                                display:shwContentSrch?'block':'none'
                                }}>
                                <Input allowClear={true} value={srchVal} 
                                disabled={showSearchInput}
                                className="input-search"
                                onChange={(e)=>{
                                    setShwCat(e.target.value!=''?true:false);
                                    setSrchVal(e.target.value);getCount(e.target.value,setTypes,setType,gradeLevel);
                                    //getlists(srchVal,setType,'story',setList);
                                }} placeholder="Search for Stories or Activities" 
                                 />
                                <div className="filter-relate">
                                <div className="teacher-absolute" style={{
                                display:shwCat?'block':'none'
                                }}>
                                    <div className="teacher_cat">
                                        {
                                            //types.filter(type=>type.name=='Stories').length>0
                                            //?
                                            //<button onClick={()=>{setType('story')}} className={"btn-teacher "+(type=='story'?'actives':'')} >Stories ({types.filter(type=>type.name=='Stories')[0]?.doc_count})</button>:
                                            //<button onClick={()=>{setType('story')}} className={"btn-teacher "+(type=='story'?'actives':'')} >Stories (0)</button>
                                            <button onClick={()=>{setType('story')}} className={"btn-teacher "+(type=='story'?'actives':'')} >Stories</button>
                                        }
                                        {
                                            //types.filter(type=>type.name=='Activities').length>0
                                            //?
                                            //<button onClick={()=>{setType('activity')}} className={"btn-teacher "+(type=='activity'?'actives':'')} >Activities ({types.filter(type=>type.name=='Activities')[0]?.doc_count})</button>
                                            //:<button onClick={()=>{setType('activity')}} className={"btn-teacher "+(type=='activity'?'actives':'')} >Activities (0)</button>
                                            <button onClick={()=>{setType('activity')}} className={"btn-teacher "+(type=='activity'?'actives':'')} >Activities</button>
                                        }
                                        
                                    </div>
                                    <div>
                                    {(!(types.filter(type=>type.name=='Stories').length>0) && type=='story') && <div className="teacher-no-data">No Data</div>}
                                    {(!(types.filter(type=>type.name=='Activities').length>0) && type=='activity') && <div className="teacher-no-data">No Data</div>}
                                        {type!='' && lists.length>0 &&
                                            <div className="stories_list list_flow" //style={{display:shwstory?'block':'none'}}
                                        >
                                            <div>
                                            <List
                                                itemLayout="horizontal"
                                                dataSource={lists}
                                                renderItem={story => (
                                                <List.Item style={{pointer:'cursor'}}  onClick={()=>{//alert(story.name)
                                                    selectOption([{_index:story._index,_source:{id:story._id},}])
                                                }}>
                                                    <List.Item.Meta
                                                    avatar={<Avatar src={story._source.image} />}
                                                    title={story._source.name}
                                                    description=""
                                                    />
                                                </List.Item>
                                                )}
                                            />
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                </div>
                            </div>
        </>
    )
}
const getCount = async(value,setTypes,setType,gradeLevel)=>{
        let queryParams = 'query='+value;
        if (gradeLevel) {
            queryParams += `&grade_level=${gradeLevel}`
        }
        let res = await TeacherApiCalls.getSearchResult(queryParams);
        //console.log('Alert',res)
        if(res?.result?.counts)
        {
            setTypes(res.result?.counts);
            setType('story');

            //getlists(value,setType,'story',setList)
        }
    }
    const getlists = async(value,index_type,setList,gradeLevel)=>{
        setList([]);
        if (value == '' || index_type=='') 
        return ;
        
        let queryParams = 'query='+value+'&index_type='+index_type;
        if (gradeLevel) {
            queryParams += `&grade_level=${gradeLevel}`
        }
        let res = await TeacherApiCalls.getSearchResult(queryParams);
        //console.log('Alert',res)
        if(res?.result?.documents?.length>0)
        {
            setList(res?.result?.documents);
            //setType(index_type);
        }else
        {
            setList([])
        }
    }
export default connect(
    ({ teacher})=>({...teacher}),
    dispatch=>bindActionCreators({...teacherActions},dispatch)
    )(withRouter(Teacher_Search))
