import { SubscriptionApiCalls } from "services/subscriptions";
import { mutation } from "./mutations";

const handleError = err => {
  return err.response || err;
};

// Actions
export const getSubscriptionsInfo = (parentID) => async dispatch => {
  try {
    const result = await SubscriptionApiCalls.getSubscriptionForParent(parentID)
    dispatch(mutation.setSubscriptionsInfo(result));
    return result;
  } catch (err) {
    handleError(err);
  }
}

