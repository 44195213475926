import { initialState } from "./state";

export const story = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOP_STORY_LIST": {
      return Object.assign({}, state, {
        topStory: [...state.topStory , ...action.data]
      });
    }
    case "SET_RECOMMENDED_STORY_LIST": {
      return {
        ...state,
        recommendedStory: [...state.recommendedStory , ...action.data]
      }
    }
    case 'UPDATE_BOOKMARK_DATA': {
      return {
        ...state,
        // Update the bookmark data for the stories
        topStory: state.topStory.map(s => {
          if (s.id === action.data.id) {
            return {...s, is_liked: !s.is_liked}
          }
          return {...s}
        }),
        recommendedStory: state.recommendedStory.map(s => {
          if (s.id === action.data.id) {
            return {...s, is_liked: !s.is_liked}
          }
          return {...s}
        }),
        onGoingStories: state.onGoingStories.map(s => {
          if (s.id === action.data.id) {
            return {...s, is_liked: !s.is_liked}
          }
          return {...s}
        }),
        likedStories: [...state.likedStories.filter(s => {
          if (s.id === action.data.id && s.is_liked === true) {
            return false
          } else {
            return true
          }
        }),
        // Add to array if it is liked
        ...(action.data.is_liked === false ? [{...action.data, is_liked: true}]: [])
        ]
      }
    }
    case 'SET_ONGOING_STORIES': {
      return {
        ...state,
        onGoingStories: [...state.onGoingStories,...action.data],
      }
    }
    case 'SET_LIKED_STORIES': {
      return {
        ...state,
        likedStories: [...state.likedStories,...action.data]
      }
    }
    case 'CLEAR_DASHBOARD_DATA':{
      return initialState;
    }
    case 'UPDATE_STORIES_STATUS': {
      switch (action.data.status) {
        case 'on_going':
          return {
            ...state,
            onGoingStory: state.onGoingStory.push(action.data)
          }
        case 'completed':
          return {
            ...state,
            onGoingStory: state.onGoingStory.filter(o => {
              if(o.id === action.data.id) {
                return false
              } else {
                return true
              }
            })
          }
        default:
          return state
      }
    }
    default:
      return state;
  }
};
