import { Story } from "services/story";
import { mutation } from "./mutations";

const handleError = (err) => {
  console.log("Error in story action : ", err);
};

// Actions
export const getTopStoryListRequest = (offset = 1) => async (dispatch) => {
  try {
    const result = await Story.getTopStories(offset);
    dispatch(mutation.setTopStoryList(result.result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getRecommendedStoryRequest = (offset = 1) => async (dispatch) => {
  try {
    const result = await Story.getRecommendedStories(offset);
    if (result) {
      dispatch(mutation.setRecommendedStoryList(result.result));
    }
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getStoryDetail = (id) => async (dispatch) => {
  try {
    const result = await Story.getStoryDetail(id);
    dispatch(mutation.setDetailedStory(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getMcqRequest = (id) => async (dispatch) => {
  try {
    const result = await Story.getMcq(id);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const submitAnswer = (payload) => async (dispatch) => {
  try {
    const result = await Story.submitAnswer(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const bookmarkStory = (story) => async (dispatch) => {
  try {
    const result = await Story.bookmarkStory(story.id);
    if (result.success) {
      dispatch(mutation.updateBookmarkedData(story));
    }
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const bookmarkOnlyStory = (story) => async (dispatch) => {
  try {
    const result = await Story.bookmarkStory(story.id);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const startStoryRequest = (payload) => async (dispatch) => {
  try {
    const result = await Story.startStory({ story: payload.id });
    if (result.success) {
      payload.status = result.status;
      dispatch(mutation.updateStoryStatus(payload));
    }
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const finishStoryRequest = (storyId) => async (dispatch) => {
  try {
    const result = await Story.finishStory(storyId);
    return result;
  } catch (err) {
    handleError(err);
  }
};


export const updateDetailedStory = (status) => async (dispatch) => {
  try {
    console.log('updateDetailedStory updateDetailedStory', status);
    dispatch(mutation.updateDetailedStory(status));
  } catch (err) {
    handleError(err);
  }
};


export const getOngoingStoriesRequest = (offset = 1) => async (dispatch) => {
  try {
    const result = await Story.getStoryOngoing("on_going", offset);
    if (result) {
      dispatch(mutation.setOngoingStories(result.result));
    }
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getLikedStoriesRequest = (offset = 1) => async (dispatch) => {
  try {
    const result = await Story.getLikedStories(offset);
    if (result) {
      dispatch(mutation.setLikedStories(result.result));
    }
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const clearDashboardData = () => async (dispatch) => {
  try {
    dispatch(mutation.clearData());
  } catch (err) {
    handleError(err);
  }
};


export const createStreakRequest = (payload) => async (dispatch) => {
  try {
    const result = await Story.createStreak(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const updateStoryTrackingData = (data) => async (dispatch)  => {
  console.log("updateStoryTrackingData",data);
  try {
    const result = await Story.updateStoryTrackingDataTask(data);
    return result
  } catch (err) {
    handleError(err);
  }
}