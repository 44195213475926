import React, { Component } from "react";
import FormField from "../../../../../components/ProfileForm/FormField/index";
import cloneDeep from "lodash/cloneDeep";
import { Form, Input, Row, Spin } from "antd";
import "./index.scss";
import { generateFilterUrl } from "../../../../../utils/commonUtils";

class ClassScheduleForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classScheduleFormFields: props.formValues ? props.formValues : classScheduleFormFields,
            selectedSchedule: '',
            filters: {
                offset: 0
            }
        };
    }

    async componentDidMount() {
        await this.fetchClassSchedules()
        if (!this.props.formValues) {
            let newFields = cloneDeep(this.state.classScheduleFormFields);
            newFields[1].options = this.props.availableSchedules?.preference_groups || [];
            newFields[1].value = this.props.selectedSchedule || '';
            newFields[1].prefilledValue = this.props.selectedSchedule || '';
            this.props.form.setFieldsValue({ [newFields[1]?.name]: newFields[1]?.value })
            this.setState({
                classScheduleFormFields: newFields
            }, () => {
                this.handleFormValidity();
            });
        } else {
            this.handleFormValidity();
        }
    }

    fetchClassSchedules = async () => {
        let urlParams = generateFilterUrl(this.state.filters);
        try {
            let res = await this.props.props.getAvailableSchedulesRequest(urlParams);
            if (res) {
                this.isFetchingMoreData = false;
                let newFields = cloneDeep(this.state.classScheduleFormFields);
                newFields[1].options = [...newFields[1].options, ...res.data?.preference_groups]
                this.setState({
                    classScheduleFormFields: newFields,
                    isLoading: false
                })
            }
        } catch (e) {
            this.setState({
                isLoading: false
            })
        }
    }

    getFormFields = () => cloneDeep(this.state.classScheduleFormFields);

    setFormFields = () => {
        this.state.classScheduleFormFields.forEach((item, index) => {
            this.props.form.setFieldsValue({ [item.name]: item.value })
        })
    }

    handleFormValidity() {
        let isValid = true;
        let noLabelCondition = (element) => element.type !== 'label';
        this.state.classScheduleFormFields.filter(noLabelCondition).forEach(element => {
            if (!element.value || (typeof element.value === "string" && element.value.trim() === '')) {
                isValid = false;
            }
        });
        this.props.onFormValidChange(isValid);
    }

    onValueChange(event, index) {
        let newclassScheduleFormFields = cloneDeep(this.state.classScheduleFormFields);
        newclassScheduleFormFields[index].value = event.target ? event.target.value : event;
        this.setState({
            classScheduleFormFields: newclassScheduleFormFields
        }, () => {
            this.props.form.setFieldsValue({ [newclassScheduleFormFields[index]?.name]: event.target ? event.target.value : event })
            this.handleFormValidity();
        });
    }

    isFormValid = e => {
        e && e.preventDefault();
        let isFormValid = false;
        this.props.form.validateFields(async (err, values) => {
            if (!err)
                isFormValid = true
        });
        return isFormValid
    };

    onScroll = (event) => {
        if (
            (
                (Math.ceil(event.target.scrollHeight - event.target.scrollTop) === (event.target.clientHeight))
                ||
                (Math.floor(event.target.scrollHeight - event.target.scrollTop) === (event.target.clientHeight))
            )
            && !this.isFetchingMoreData
        ) {
            this.handlePageChange()
        }
    }

    handlePageChange = () => {
        if (this.state.classScheduleFormFields[1]?.options?.length < this.props.availableSchedules?.total_count) {
            this.isFetchingMoreData = true;
            let filters = {
                ...this.state.filters,
                offset: this.state.filters.offset + 1
            }
            this.setState({
                filters,
                isLoading: true,
            }, () => this.fetchClassSchedules())
        }
    }

    showLoadMoreBtn = () => this.state.classScheduleFormFields[1]?.options?.length < this.props.availableSchedules?.total_count

    render() {
        const { getFieldDecorator } = this.props.form;
        const fieldComponents = this.state.classScheduleFormFields.map(
            (element, index) => {
                return (
                    <Row>
                        <FormField
                            {...element}
                            key={index}
                            getFieldDecorator={getFieldDecorator}
                            fieldType={element.type}
                            onValueChange={(event) => {
                                this.onValueChange(event, index)
                            }}
                        />
                    </Row>
                );
            }
        );
        return (
            <div id={'schedule-form'} onScroll={this.onScroll} className="class-schedule-form-fields form-fields">
                <Form
                    onSubmit={this.isFormValid}
                    name="schedule-form"
                    initialValues={{ remember: true }}
                    className="custom-form"
                >
                    <Input.Group>
                        <Row gutter={1}>
                            <div className={'form-heading'}>{fieldComponents[0]}</div>
                        </Row>
                        {fieldComponents[1]}
                    </Input.Group>
                    {this.state.isLoading && <div className={'load-more'}>
                        <Spin />
                        Loading...
                    </div>}
                    {this.showLoadMoreBtn() && <div onClick={this.handlePageChange} className={'load-more-btn'}>
                        {this.state.isLoading ? 'Loading' : 'Load More'}
                        {this.state.isLoading && <Spin />}
                    </div>}
                </Form>
            </div>
        );
    }
}


const classScheduleFormFields = [
    {
        title: `SELECT A CLASS SCHEDULE FROM BELOW`,
        type: "label",
        className: "",
        value: ''
    },
    {
        title: ``,
        type: "cardSelector",
        isMultiSelect: false,
        className: "",
        value: '',
        error: 'Please select one',
        name: 'class-schedule',
        options: []
    },
];
export default Form.create({ name: 'schedule-form' })(ClassScheduleForm);
