import { Api } from "./config/request";

export const getTopStories = (offest) => Api.getSearchRequest('story/v1/?top_story=True&offset='+offest);

export const getRecommendedStories = (offest) => Api.getSearchRequest('story/v1/recommended-story/?offset='+offest);

export const getStoryDetail = id => Api.getRequest(`story/v1/${id}/`)

export const getMcq = id => Api.getRequest(`story-assessment/v1/story/${id}/question/`);

export const getContentSearch = params => Api.getSearchRequest(`global-search/v1/?${params}`);

export const submitAnswer = payload => Api.postRequest('story-assessment/v1/answer/', payload);

export const bookmarkStory = storyId => Api.postRequest(`story/v1/like/?story_id=${storyId}`);

export const startStory = payload => Api.postRequest('story/v1/progress/', payload);

export const finishStory = storyId => Api.putRequest(`story/v1/progress/${storyId}/`);

export const getStoryByStatus = (status) => Api.getListRequest(`story/v1/?status=${status}`);
export const getStoryOngoing = (status,offest) => Api.getSearchRequest(`story/v1/?status=${status}&offset=${offest}`);
export const getLikedStories = (offest) => Api.getSearchRequest('story/v1/?like=True&offset='+offest);

export const createStreak = (payload) => Api.postRequest('user/v1/streak/', payload);

export const updateStoryTrackingDataTask = (data) => Api.postRequest('story/v1/track-story/', data);

export const Story = {
  getTopStories,
  getRecommendedStories,
  getStoryDetail,
  getMcq,
  submitAnswer,
  bookmarkStory,
  startStory,
  finishStory,
  getStoryByStatus,
  getLikedStories,
  getStoryOngoing,
  createStreak,
  updateStoryTrackingDataTask,
}
