import { TestApiCalls } from "../../services/tests";
import { mutation } from "./mutations";

const handleError = err => {

};

// Actions
export const getAllTests = (crmLsqId) => async dispatch => {
    try {
        const result = await TestApiCalls.getAllTestsReq(crmLsqId);
        dispatch(mutation.setTestList(result))
        return result;
    } catch (err) {
        handleError(err);
    }
};

export const updateTestReport = (updateReportObj) => async dispatch => {
    try {
        const result = await TestApiCalls.updateTestReport(updateReportObj);
        console.log("updateTestReport======",updateTestReport);
        return result;
    } catch (err) {
        handleError(err);
    }
};