import React, { Component } from "react";
import timer from "assets/image/Timer.png";
import CloseBlackIcon from 'assets/image/close-black.svg';
import "./Timer.scss";

class Timer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minutes: this.props.minutes,
      seconds: this.props.seconds,
      timerRunning: false
    };

    this.interval = null;
  }
  componentDidMount() {
    console.log("timer props minutes,seconds:",this.props.minutes,this.props.seconds);
    this.handleStartClick();
  }

  // componentWillUnmount = async () => {
  //   this.handlePauseClick();
  //   // calculate total time in seconds
  //   const { minutes, seconds } = this.state;
  //   const total_time = minutes * 60 + seconds;
  //   console.log("setting total time from child:",total_time);
  //   await this.props.setTotalTime(total_time);
  //   clearInterval(this.interval);
  // }

  closeModal = async  () => {
    this.handlePauseClick();
    console.log("prop minutes,seconds:",this.props.minutes,this.props.seconds);
    const { minutes, seconds } = this.state;
    const total_time = minutes * 60 + seconds;
    const props_total_time = this.props.minutes * 60 + this.props.seconds;
    const time_left = total_time - props_total_time;
    console.log("setting total time from child:",total_time);
    await this.props.setTotalTime(time_left);
    clearInterval(this.interval);
    this.props.closeModal();
  }

  handleStartClick = () => {
    this.setState({ timerRunning: true }, () => {
      this.interval = setInterval(() => {
        const { minutes, seconds } = this.state;
        if (seconds === 59) {
          this.setState({
            minutes: minutes + 1,
            seconds: 0
          });
        } else {
          this.setState({ seconds: seconds + 1 });
        }
      }, 1000);
    });
  };

  handlePauseClick = () => {
    this.setState({ timerRunning: false }, () => {
      clearInterval(this.interval);
    });
  };

  render() {
    const { minutes, seconds } = this.state;

    return (
      <div className="timer">
        <div className="timer-header">
          <h1>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h1>
          <img src={timer} alt="Timer logo" />
        </div>
        <img src={CloseBlackIcon} className="icon-close" alt="close" onClick={this.closeModal} />
      </div>
      
    );
  }
}

export default Timer;
