import { AnnouncementApiCalls } from "../../services/announcements";
import { mutation } from "./mutations";

const handleError = (err) => {
  console.log("Error in Login action : ", err);
};

export const getHomework = (batchID) => async (dispatch) => {
  try {
    const result = await AnnouncementApiCalls.getHomeworkForBatch(batchID);
    dispatch(mutation.setHomeworkList(result));
    console.log("result >", result.data);
    let data = result.data.reverse();
    console.log("result 2 >", data);
    return data;
  } catch (err) {
    handleError(err);
  }
};
