import React, { Component } from 'react';
import FormField from '../../../../../components/ProfileForm/FormField/index';
import cloneDeep from 'lodash/cloneDeep';
import { Col, Form, Input, Row } from 'antd';
import "./index.scss";
import { stateInfo } from './states';

class AddressForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addressFormFields: props.formValues ? props.formValues : addressFormFields,
            selectedState: "Andaman & Nicobar",
        }
    }

    componentDidMount() {
        if (!this.props.formValues) {
            let newFields = cloneDeep(this.state.addressFormFields);
            newFields[0].value = this.props.address;
            newFields[1].value = this.props.locality;
            newFields[2].value = this.props.state
            newFields[3].value = this.props.city;
            newFields[4].value = this.props.pincode;

            this.setState({
                addressFormFields: newFields,
                selectedState: newFields[2].value.trim() != "" ? newFields[2].value : "Andaman & Nicobar",
            }, () => {
                this.setFormFields()
                this.handleFormValidity();
            });
        } else {
            this.handleFormValidity();
        }
    }

    getFormFields = () => cloneDeep(this.state.addressFormFields);

    handleFormValidity() {
        let isValid = true;
        this.state.addressFormFields.forEach(element => {
            if (element.value == null || (typeof (element.value) == "string" && element.value.trim() == '')) {
                isValid = false;
            }
        });

        if (isNaN(this.state.addressFormFields[4].value)) {
            isValid = false;
        }
        this.props.onFormValidChange(isValid);
    }

    onStateChange(event, index) {
        let newFields = cloneDeep(this.state.addressFormFields);
        newFields[index].value = event;
        newFields[index + 1].value = "";
        this.setState({
            addressFormFields: newFields,
            selectedState: event
        }, () => {
            this.props.form.setFieldsValue({ city: '' })
            this.handleFormValidity();
        });
    }

    onValueChange(event, index) {
        let newAddressFormFields = cloneDeep(this.state.addressFormFields);
        newAddressFormFields[index].value = event.target ? event.target.value : event;
        this.setState({
            addressFormFields: newAddressFormFields
        }, () => {
            //set form values
            // this.setFormFields()
            this.handleFormValidity();
        })
    }

    isFormValid = e => {
        e && e.preventDefault();
        let isFormValid = false;
        this.props.form.validateFields(async (err, values) => {
            if (!err)
                isFormValid = true
        });
        return isFormValid
    };

    setFormFields = () => {
        this.state.addressFormFields.forEach((item, index) => {
            this.props.form.setFieldsValue({ [item.name]: item.value?.toString() })
        })
    }

    getFieldComponents = (getFieldDecorator) => this.state.addressFormFields?.map((field, index) => {
        return (
            <Row>
                <FormField
                    {...field}
                    key={index}
                    onValueChange={(event) => {
                        if (index !== 2)
                            this.onValueChange(event, index)
                        else
                            this.onStateChange(event, index)
                    }}
                    fieldType={field.type}
                    options={typeof field.options === 'function' ? field.options(this.state.selectedState) : field.options}
                    getFieldDecorator={getFieldDecorator}
                />
            </Row>
        )
    })

    render() {
        const { getFieldDecorator } = this.props.form;
        const fieldComponents = this.getFieldComponents(getFieldDecorator)
        return (
            <div>
                <div className='address-form-fields form-fields'>
                    <Form
                        onSubmit={this.isFormValid}
                        name="school-form"
                        initialValues={{ remember: true }}
                        className="custom-form"
                    >
                        <Input.Group>
                            <Row>
                                {fieldComponents[0]}
                            </Row>
                            <Row>
                                {fieldComponents[1]}
                            </Row>
                            <Row>
                                <Col span={11}>
                                    {fieldComponents[2]}
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    {fieldComponents[3]}
                                </Col>
                            </Row>
                            <Col span={11}>
                                {fieldComponents[4]}
                            </Col>
                        </Input.Group>
                    </Form>
                </div>
            </div>
        )
    }
}

const addressFormFields = [
    {
        title: "LINE 1",
        name: "address-line-1",
        placeholder: "House no, street name",
        value: "",
        type: "text",
        error: 'This cannot be empty',
        className: ""
    },
    {
        title: "LINE 2",
        name: "address-line-2",
        placeholder: "Locality",
        value: "",
        error: 'This cannot be empty',
        type: "text",
        className: ""
    },
    {
        title: "STATE",
        name: "state",
        placeholder: "Select One",
        value: "",
        type: "dropdown",
        error: 'This cannot be empty',
        className: "",
        options: [...Object.keys(stateInfo).map(stateName => ({
            title: stateName,
            value: stateName,
        }))]
    },

    {
        title: "CITY",
        name: "city",
        placeholder: "Select One",
        value: "",
        type: "dropdown",
        className: "",
        error: 'This cannot be empty',

        options: (selectedState) => stateInfo[selectedState].split("|").map(city => ({
            title: city,
            value: city,
        }))
    },
    {
        title: "PINCODE",
        name: "pincode",
        placeholder: "eg. 110054",
        value: "",
        type: "number",
        className: "",
        error: 'This cannot be empty',

    }

]

export default Form.create({ name: 'address-form' })(AddressForm);

