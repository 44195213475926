import libraryIcon from "../../../../../assets/image/library.png";
import moment from "moment";
import React from "react";
import "./index.scss";
import { getTimeComponents } from "../../../utils";
import * as EVENT from "../../../../../utils/eventKeys";

export const NextClass = ({
  nextClassToAttend,
  sendAnalytics,
  nextClassTime,
  ...props
}) => {
  let start = moment(nextClassTime.classStartDateTime)
    .utc(true)
    .subtract(1800, "seconds")
    .format("hh:mm A");
  // var newDateObj = moment().subtract(1800, "seconds");

  return (
    <div className="next-class-card shadow-sm bg-white rounded-6 rounded gradient-color mobile-view">
      <div className="circle-1" />
      <div className="circle-2" />
      <div className="circle-3" />
      <div className="circle-4" />
      <div className="p-4">
        <div>
          <div className="cardTitle-row next-text-row">
            <div className=" text-white small next-class">
              {nextClassTime?.classStartDateTime
                ? moment().isBetween(
                    nextClassTime.classStartDateTime,
                    nextClassTime.classEndDateTime
                  )
                  ? "Now"
                  : "NextClass"
                : "Batches not yet started"}
            </div>
          </div>
          <section className="post-heading">
            <div className="row">
              <div className="col-md-11 text-left">
                <div className="media media-card">
                  <div className="row">
                    <div className="cardTitle-row flex-mobile-view col-md-6 col-sm-6 col-6">
                      <h4 className="cardTitle mb-0 text-white date-month">
                        {getTimeComponents(
                          "date",
                          nextClassTime.classStartDateTime,
                          nextClassTime.classEndDateTime
                        ) +
                          " " +
                          getTimeComponents(
                            "month",
                            nextClassTime.classStartDateTime,
                            nextClassTime.classEndDateTime
                          )}
                      </h4>
                      <div className="time-slot">
                        {getTimeComponents(
                          "timeSlot",
                          nextClassTime.classStartDateTime,
                          nextClassTime.classEndDateTime
                        )}
                      </div>
                    </div>
                    {nextClassToAttend && nextClassTime.join == false ? (
                      <div className="Class-btn col-md-6 col-sm-6 col-6">
                        <button
                          value="classbtn"
                          //FREAD-1342
                          id="FLV_JOIN"
                          //FREAD-1342
                          onClick={() => {
                            window.open(
                              nextClassToAttend?.class_link,
                              "_blank"
                            );
                            //FREAD-1342
                            props.sendCtEvents(EVENT.FLIVE_JOIN_BUTTON);
                            //FREAD-1342
                          }}
                          className="join-btn-hero"
                          disabled={!nextClassTime.join}
                          // title="Join Button will be activated 30 minutes prior to your session timing"
                          // alt="Join Button will be activated 30 minutes prior to your session timing"
                        >
                          Join
                        </button>
                        <label className="customLabelStyle">
                          Activates at {start}
                        </label>
                      </div>
                    ) : (
                      <div className="Class-btn col-md-6 col-sm-6 col-6">
                        <button
                          value="classbtn"
                          onClick={() =>
                            window.open(nextClassToAttend?.class_link, "_blank")
                          }
                          className="join-btn-hero"
                          disabled={!nextClassTime.join}
                          // title="Join Button will be activated 30 minutes prior to your session timing"
                          // alt="Join Button will be activated 30 minutes prior to your session timing"
                        >
                          Join
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="media-body pl-0">
            <h4 className="mb-0 ml-0 text-left text-white">
              {nextClassToAttend ? nextClassToAttend.session?.name : null}
            </h4>
            <h6 className="post-text text-white session-desc">
              {nextClassToAttend
                ? nextClassToAttend.session?.description
                : null}
            </h6>
          </section>
        </div>
      </div>
    </div>
  );
};
