import React, {useEffect} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './Results.scss';
import {columns} from './constants';
import {Table} from 'antd';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as reportActions from "../../store/reports/actions";

const Results = (props) => {
    useEffect(() => {
        props.getAllReports(props.reduxData?.student?.crm_lsq_id)
    }, []);
    return (

        <div className="component-flive main-wrapper">
            {/* //FLV-113 */}
            {/* <Navbar/> */}
                {!props.reduxData?.user?.navbarFlag && <Navbar />}
            <div className={["results-screen",props.reduxData?.user?.navbarFlag?"padding-top-0":""].join(" ")}>
            {/* //FLV-113 */}
                <p className='back-btn' onClick={() => props.history.push('/user')}>&lt; BACK</p>
                <h1>My Results</h1>
                <div className="table-container">
                    <Table pagination={false} dataSource={props.reduxData?.reports?.list || []} columns={columns}/>
                </div>
            </div>
        </div>
    );
}


export default connect(
    (state) => ({reduxData: {...state}}),
    dispatch => bindActionCreators({
        ...reportActions,
    }, dispatch,)
)(Results);
