const setAnnouncementList = (data) => ({
  type: "SET_ANNOUNCEMENT_LIST",
  data,
});

const clearAnnouncements = () => ({
  type: "CLEAR_ANNOUNCEMENTS",
});

export const mutation = {
  setAnnouncementList,
  clearAnnouncements,
};
