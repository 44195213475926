import React from 'react';
import moment from "moment";

export const columns = [
    {
        title: 'REPORT TITLE',
        dataIndex: 'title',
        key: 'title',
        width: "20%",
        align: 'left',
    },
    {
        title: 'DESCRIPTION',
        dataIndex: 'description',
        key: 'description',
        width: "35%"
    },
    {
        title: 'DATE',
        dataIndex: 'created_at',
        key: 'created_at',
        width: "10%",
        render: (text, record) =>
            <div>{record.created_at ? moment(record.created_at * 1000).format('DD/MM/YY') : '-'}</div>
    },
    {
        title: 'TAG',
        dataIndex: 'test_lable',
        key: 'tag',
        width: "15%",
        align: 'center',
        render: (text, record) => record.test_lable &&
            <div className="report-pill"><span>{record.test_lable}</span></div>
    },
    {
        title: 'LINK',
        dataIndex: 'result_link',
        key: 'result_link',
        width: "20%",
        align: 'center',
        render: (text, record) => <a target={"_blank"} href={`${record.result_link}`}>View Report</a>
    },
];

