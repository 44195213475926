import React, { Component } from "react";
import FormField from "../../../../../components/ProfileForm/FormField/index";
import cloneDeep from "lodash/cloneDeep";
import { Button, Col, Form, Input, Row } from "antd";
import "./index.scss";

const NUM_FIELDS = 4;

class SchoolForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolFormFields: props.formValues ? props.formValues : schoolFormFields,
    };
  }

  componentDidMount() {
    if (!this.props.formValues) {
      let newFields = cloneDeep(this.state.schoolFormFields);
      newFields[0].value = this.props.schoolName;
      newFields[1].value = this.props.monthlyExpenses;
      newFields[2].value = this.props.expectedOutcome;
      if (this.props.otherClasses.length > 0) {
        this.props.otherClasses.forEach((element, index) => {
          newFields.push(cloneDeep(newFields[newFields.length - 1]));
          newFields[newFields.length - 2].value = element;
          newFields[newFields.length - 1].title = "";
          newFields[newFields.length - 1].name = `other-classes-${index + 1}`;
        });
        newFields = newFields.filter(
          (element, index) => index <= 2 || element.value
        );
      }

      newFields[newFields.length - 1].isNotRequired = true;

      this.setState(
        {
          schoolFormFields: newFields,
        },
        () => {
          //set form values
          this.setFormFields();
          this.handleFormValidity();
        }
      );
    } else {
      this.handleFormValidity();
    }
  }

  setFormFields = () => {
    this.state.schoolFormFields.forEach((item, index) => {
      this.props.form.setFieldsValue({ [item.name]: item.value?.toString() });
    });
  };

  getFormFields = () => cloneDeep(this.state.schoolFormFields);

  handleFormValidity() {
    let isValid = true;
    this.state.schoolFormFields
      .slice(0, this.state.schoolFormFields.length - 1)
      .forEach((element) => {
        if (
          element.value == null ||
          (typeof element.value == "string" && element.value.trim() == "")
        ) {
          isValid = false;
        }
      });

    if (
      this.state.schoolFormFields.length == NUM_FIELDS &&
      this.state.schoolFormFields[
        this.state.schoolFormFields.length - 1
      ].value.trim() == ""
    ) {
      isValid = false;
    }

    if (isNaN(this.state.schoolFormFields[1].value)) {
      isValid = false;
    }

    this.props.onFormValidChange(isValid);
  }

  handleField(event, type, rowIndex) {
    const fields = cloneDeep(this.state.schoolFormFields);
    if (!fields?.[fields.length - 1]?.value && type === "add") return;
    if (type === "add")
      return this.setState(
        (state) => {
          fields.push({ ...fields[fields.length - 1] });
          fields[fields.length - 1].title = "";
          fields[fields.length - 1].value = "";
          fields[fields.length - 1].isNotRequired = false;
          let secondLastFieldName = fields[fields.length - 1]?.name;
          fields[fields.length - 1].name = `other-classes-${
            +secondLastFieldName?.split("-")?.[2] + 1
          }`;
          return {
            ...state,
            schoolFormFields: fields,
          };
        },
        () => this.handleFormValidity()
      );
    return this.setState(
      (state) => {
        fields.splice(3 + rowIndex, 1);
        return {
          ...state,
          schoolFormFields: fields,
        };
      },
      () => this.handleFormValidity()
    );
  }

  onValueChange(event, index) {
    let newSchoolFormFields = cloneDeep(this.state.schoolFormFields);
    newSchoolFormFields[index].value = event.target.value;
    this.setState(
      {
        schoolFormFields: newSchoolFormFields,
      },
      () => {
        this.handleFormValidity();
      }
    );
  }

  isFormValid = (e) => {
    e && e.preventDefault();
    let isFormValid = false;
    this.props.form.validateFields(async (err, values) => {
      if (!err) isFormValid = true;
    });
    return isFormValid;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const fieldComponents = this.state.schoolFormFields.map(
      (element, index) => {
        return (
          <Row>
            <FormField
              key={index}
              // index={index - 3}
              {...element}
              getFieldDecorator={getFieldDecorator}
              fieldType={element.type}
              onValueChange={(event) => {
                let allowUpdate = true;
                if (
                  element?.regex &&
                  event.target.value !== "" &&
                  !event.target.value.match(element.regex)
                )
                  allowUpdate = false;
                allowUpdate && this.onValueChange(event, index);
              }}
            />
          </Row>
        );
      }
    );
    return (
      <div>
        <div className="school-form-fields form-fields">
          <Form
            onSubmit={this.isFormValid}
            name="school-form"
            initialValues={{ remember: true }}
            className="custom-form"
          >
            <Input.Group>
              <Row gutter={1}>
                <Col span={11}>{fieldComponents[0]}</Col>
                <Col span={2}></Col>
                <Col span={11}>{fieldComponents[1]}</Col>
              </Row>
              <Row>{fieldComponents[2]}</Row>
              <Row>
                <Col span={11}>
                  {fieldComponents.slice(3).map((fieldCompoment, index) => (
                    <div>
                      {fieldCompoment}
                      {index > 0 ? (
                        <Button
                          className="flat-btn remove-btn remove-btn-mobile"
                          onClick={(event) =>
                            this.handleField(event, "remove", index)
                          }
                        >
                          - Remove
                        </Button>
                      ) : null}
                    </div>
                  ))}
                </Col>
                <Col span={1}></Col>
                <Col span={10}>
                  <Button
                    className="flat-btn add-another-btn"
                    onClick={(event) => this.handleField(event, "add")}
                  >
                    + Add Another
                  </Button>
                  {fieldComponents.slice(3).map((item, index) => {
                    return (
                      index !== 0 && (
                        <Button
                          className="flat-btn add-another-btn"
                          onClick={(event) =>
                            this.handleField(event, "remove", index)
                          }
                        >
                          - Remove
                        </Button>
                      )
                    );
                  })}
                </Col>
              </Row>
            </Input.Group>
          </Form>
        </div>
      </div>
    );
  }
}

const schoolFormFields = [
  {
    title: "SCHOOL NAME",
    name: "school-name",
    placeholder: "The school your child attends",
    error: "This cannot be empty",
    value: "",
    type: "text",
    className: "",
    regex: /^[a-zA-Z\s]+$/,
    regexErrorMessage: "Only alphabets allowed",
  },
  {
    title: "APPROX MONTHLY EXPENSES",
    name: "monthly-expenses",
    placeholder: "Monthly expenditure on school",
    error: "This cannot be empty",
    value: "",
    type: "number",
    className: "",
  },
  {
    title: "EXPECTED OUTCOME OUT OF FLIVE",
    name: "expected-outcome",
    placeholder: "What do you expect out of this program",
    error: "This cannot be empty",
    value: "",
    type: "textarea",
    className: "",
  },
  {
    title: "OTHER CLASSES ATTENDED BY CHILD",
    name: "other-classes-0",
    placeholder: "Eg: Art Class, Karate, etc",
    value: "",
    type: "text",
    error: "This cannot be empty",
    className: "",
    regex: /^[a-zA-Z\s]+$/,
    regexErrorMessage: "Only alphabets allowed",
  },
];
export default Form.create({ name: "school-form" })(SchoolForm);
