import questionIcon from "../../../../../assets/image/question-icon.png";
import React from "react";
import { connect } from "react-redux";
import "./index.scss";
import { TabList } from "../../../../../components/TabList";
import { AnnouncementsList } from "../../AnnouncementsList";
import AnnouncementsEmpty from "../../../../../components/AnnouncementsEmpty/AnnouncementsEmpty";
import InfoIcon from "../../../../../assets/image/info.svg";
import TestBook from "../../../../../assets/image/testBook.svg";
import TestList  from "../../TestsList";

const AnnouncementsCard = ({
  reduxData,
  isMobileView,
  openAnnouncementsModal,
  openTestsModal,
  openCustomerSupportModal,
}) => {
  console.log("reduxData", reduxData);
  return (
    <div
      className={`osahan-account-page-left shadow-sm bg-white rounded-6 mt-4 update-card-${
        isMobileView ? "mobile-view" : "desktop"
      } rounded announcements`}
    >
      <div className="osahan-user text-center">
        <div
          className={`osahan-user-media ${
            isMobileView ? "announcement-mobile-view" : ""
          }`}
        >
          <div className="osahan-user-media-body">
            {/* {!isMobileView && <div onClick={openCustomerSupportModal}>
                        <img id="question-icon" src={questionIcon} />
                    </div>
                    } */}
            <TabList
              isMobileView={isMobileView}
              onTabOneViewAll={openAnnouncementsModal}
              firstTabLabel={
                <div style={{ display: "flex" }}>
                  <img className="tabIcon" src={InfoIcon} />
                  Homeworks
                </div>
              }
              firstTabData={
                reduxData?.homeworks?.homeworks?.data?.slice(
                  0,
                  isMobileView ? 2 : 3
                )?.length > 0 ? (
                  <>
                    <AnnouncementsList
                      data={reduxData?.homeworks?.homeworks?.data || []}
                      openAnnouncementsModal={openAnnouncementsModal}
                    />
                    <button
                      onClick={openAnnouncementsModal}
                      //FREAD-1342
                      id="HW_VIEW_ALL"
                      //FREAD-1342
                      className="gray-btn"
                    >
                      See All
                    </button>
                  </>
                ) : (
                  <>
                    <AnnouncementsEmpty />
                    <button
                      onClick={openAnnouncementsModal}
                      className="gray-btn"
                      //FREAD-1342
                      id="HW_VIEW_ALL"
                      //FREAD-1342
                    >
                      See All
                    </button>
                  </>
                )
              }
              secondTabLabel={
                <div style={{ display: "flex" }}
                //FREAD-1342
                id="FLV_TEST_TAB"
                //FREAD-1342
                >
                  <img className="tabIcon" src={TestBook} />
                  ASSESSMENT
                </div>
              }
              secondTabData={
                reduxData?.tests?.list?.slice(0, isMobileView ? 2 : 3)?.length >
                0 ? (
                  <>
                    <TestList data={reduxData?.tests?.list} />{" "}
                    <button onClick={openTestsModal} className="gray-btn">
                      See All
                    </button>
                  </>
                ) : (
                  <>
                    <AnnouncementsEmpty message={"No tests are available!"} />
                    <button onClick={openTestsModal} className="gray-btn">
                      See All
                    </button>
                  </>
                )
              }
              onTabTwoViewAll={openTestsModal}
            />
          </div>
          {/*<button
                    onClick={openAnnouncementsModal}
                    className="gray-btn">
                    See All
                </button>*/}
        </div>
      </div>
    </div>
  );
};
export default connect(
  (state) => ({ reduxData: { ...state } }),
  null
)(AnnouncementsCard);
