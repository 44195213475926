import React, {useEffect, useState} from "react";
import './index.scss'
import {Form, Tooltip} from "antd";

const CardSelector = React.memo(({getFieldDecorator, ...props}) => {
    const [selectedValue, setSelectedValue] = useState(props.value);
    const [isScheduleSelected, setIsScheduleSelected] = useState(props.prefilledValue);
    useEffect(() => {
        setSelectedValue(props.value)
    }, [props.value]);

    useEffect(() => {
        setIsScheduleSelected(!!props.prefilledValue)
    }, [props.prefilledValue]);
    let cards = (
        <Form.Item
        >

            <div className={['schedule-card-section', isScheduleSelected && 'disable'].join(' ')}>
                {
                    props.options && props.options.length > 0 && props.options.map((item, index) => {

                            return (
                                getFieldDecorator(props.name, {
                                    rules: [{required: true, message: props.error}],
                                })
                                (<div onClick={() => {
                                        if (!isScheduleSelected) {
                                            setSelectedValue(item.group_id)
                                            props.onValueChange(item.group_id)
                                        }
                                    }}
                                      className={selectedValue !== item.group_id ? 'card' : 'card selected'}>
                                        <div className={'content'}>
                                            <p className={'heading'}>{`Schedule ${++index}`}</p>
                                            <div className={'time-slots-section'}>
                                                {renderTimeSlots(item.timeslots)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    )
                }
            </div>
        </Form.Item>
    )
    if (isScheduleSelected)
        cards = <Tooltip title={'Schedule re-selection not allowed'}>{cards}</Tooltip>
    return cards
})

function renderTimeSlots(slots) {
    return slots && slots.length > 0 && slots.map(slot => (
        <div className={'time-slot'}>
            <p className={'day-name'}>{returnWeekDay(slot.weekday) || ' '}</p>
            {`${returnTime(slot.start_second)} - ${returnTime(slot.start_second + slot.duration)}`}
        </div>
    ))
}

function returnTime(startSeconds) {
    try {
        let timezoneOffsetSeconds = -(new Date().getTimezoneOffset() * 60)
        let totalStartTimeInSeconds = timezoneOffsetSeconds + startSeconds
        let hourIn24 = Math.floor(totalStartTimeInSeconds / 3600);
        let hourIn12 = hourIn24 % 12 || 12;
        let mins = Math.floor(totalStartTimeInSeconds % 3600 / 60).toString().padStart(2, '0');
        return `${hourIn12}:${mins} ${(hourIn24 < 12 || hourIn24 === 24) ? 'AM' : 'PM'}`
    } catch (e) {
    }
}

function returnWeekDay(weekday) {
    switch (weekday) {
        case 0:
            return 'Sun';
        case 1:
            return 'Mon';
        case 2:
            return 'Tue';
        case 3:
            return 'Wed';
        case 4:
            return 'Thu';
        case 5:
            return 'Fri';
        case 6:
            return 'Sat'

    }

}

export default CardSelector;
