import { CMFluencyApiCalls } from "../../services/cmfluency";

import { mutation } from "./mutations";

const handleError = (err) => {
  console.log("Error in cmfluency action : ", err);
  return err.response || err;
};

// Actions
export const handleGradeDropDownModal = (boolValue) => async (dispatch) => {
  try {
    dispatch({ type: "SET_GRADE_DROPDOWN_MODAL", data: boolValue });
  } catch (err) {
    handleError(err);
  }
};

export const setRocketLoader = (bool) => async (dispatch) => {
  try {
    dispatch({ type: "SET_CM_ROCKET_LOADER", data: bool });
  } catch (err) {
    handleError(err);
  }
};

export const handleCMCongratulationModal = (bool) => async (dispatch) => {
  try {
    dispatch({ type: "SET_CM_CONGRATULATION_MODAL", data: bool });
  } catch (err) {
    handleError(err);
  }
};

export const setCMFluencyGrade = (Value) => async (dispatch) => {
  try {
    dispatch({ type: "SET_CM_FLUENCY_GRADE", data: Value });
  } catch (err) {
    handleError(err);
  }
};

export const getPassage = (params) => async (dispatch) => {
  try {
    const result = await CMFluencyApiCalls.getPassage(params);
    let passageObj = {
      cmfluencyPassage: result?.data?.content,
      cmfluencyTitle: result?.data?.title,
      cmfluencyPassageId: result?.data?.id,
    };
    dispatch(mutation.setCMFluencyPassage(passageObj));
    return result?.data?.content;
  } catch (err) {
    handleError(err);
  }
};

export const getReportData = (params) => async (dispatch) => {
  try {
    const result = await CMFluencyApiCalls.getReportData(params);
    dispatch(mutation.setReportData(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const clearReportData = () => async (dispatch) => {
  try {
    dispatch({ type: "CLEAR_REPORT_DATA", data: {} });
  } catch (err) {
    handleError(err);
  }
};

export const setAudioUrl = (Value) => async (dispatch) => {
  try {
    dispatch({ type: "SET_CM_FLUENCY_AUDIO_URL", data: Value });
  } catch (err) {
    handleError(err);
  }
};

export const setAudioError = (Value) => async (dispatch) => {
  try {
    dispatch({ type: "SET_CM_FLUENCY_AUDIO_ERROR", data: Value });
  } catch (err) {
    handleError(err);
  }
};

export const sendCMFluencyData = (payload, isLogin) => async (dispatch) => {
  try {
    dispatch(mutation.setRocketLoader(true));
    let result = await CMFluencyApiCalls.storeCMFluencyData(payload);
    result = result?.data?.data;
    let fluencyResult = {};
    fluencyResult["isSubmitted"] = true;
    console.log("sendCMFluencyData =====", result);
    if (result?.status) {
      fluencyResult["fluencyData"] = result;
      fluencyResult["error"] = false;
      fluencyResult["isCongratulationModal"] = true;
      fluencyResult["audioError"] = "";
      if (isLogin) {
        await CMFluencyApiCalls.cmfCommunication();
      }
    }
    dispatch(mutation.setCMFluencyResult(fluencyResult));
    return result;
  } catch (err) {
    dispatch(mutation.setAudioUrl(null));
    let fluencyResult = {};
    fluencyResult["isSubmitted"] = false;
    fluencyResult["fluencyData"] = {};
    fluencyResult["error"] = true;
    fluencyResult["isCongratulationModal"] = false;
    fluencyResult["audioError"] = err?.data?.data?.ExceptionMessage;
    dispatch(mutation.setCMFluencyResult(fluencyResult));
    handleError(err);
  }
};

export const uploadAudio = (payload) => async (dispatch) => {
  try {
    const response = await CMFluencyApiCalls.uploadAudio(payload);
    console.log("result response===", response);
    if (response.success) {
      return response?.result?.file;
    }
  } catch (err) {
    handleError(err);
  }
};

export const setRcScore = (Value) => async (dispatch) => {
  try {
    dispatch({ type: "SET_RC_TEST_SCORE", data: Value });
  } catch (err) {
    handleError(err);
  }
};

export const setCmFulencyViewId = (Value) => async (dispatch) => {
  try {
    dispatch({ type: "SET_CM_FLUENCY_VIEW_ID", data: Value });
  } catch (err) {
    handleError(err);
  }
};

export const clearCmFulencyViewId = (Value) => async (dispatch) => {
  try {
    dispatch({ type: "CLEAR_CM_FLUENCY_VIEW_ID", data: null });
  } catch (err) {
    handleError(err);
  }
};

export const iroSendCMFluencyData = (payload, isLogin) => async (dispatch) => {
  let res = await CMFluencyApiCalls.storeCMFluencyData(payload)
    .then(async (result) => {
      result = result?.data?.data;
      let fluencyResult = {};
      fluencyResult["isSubmitted"] = true;
      console.log("sendCMFluencyData =====", result);
      if (result?.status) {
        dispatch(mutation.setRocketLoader(true));
        fluencyResult["fluencyData"] = result;
        fluencyResult["error"] = false;
        fluencyResult["isCongratulationModal"] = true;
        fluencyResult["audioError"] = "";
        if (isLogin) {
          await CMFluencyApiCalls.cmfCommunication();
        }
      }
      dispatch(mutation.setCMFluencyResult(fluencyResult));
      return result;
    })
    .catch((err) => {
      dispatch(mutation.setAudioUrl(null));
      let fluencyResult = {};
      fluencyResult["isSubmitted"] = false;
      fluencyResult["fluencyData"] = {};
      fluencyResult["error"] = true;
      fluencyResult["isCongratulationModal"] = false;
      fluencyResult["audioError"] = err?.data?.data?.ExceptionMessage;
      dispatch(mutation.setCMFluencyResult(fluencyResult));
      handleError(err);
    });
  return res;
};
