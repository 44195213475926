import { initialState } from "./state";

export const cmfluency = (state = initialState, action) => {
  switch (action.type) {
    case "SET_GRADE_DROPDOWN_MODAL":
      return {
        ...state,
        cmGradeModal: action.data,
      };
    case "SET_CM_FLUENCY_GRADE":
      return {
        ...state,
        cmfluencyGrade: action.data,
      };
    case "SET_CM_FLUENCY_PASSAGE":
      return {
        ...state,
        cmfluencyPassage: action.data.cmfluencyPassage,
        cmfluencyTitle: action.data.cmfluencyTitle,
        cmfluencyPassageId: action.data.cmfluencyPassageId,
      };
    case "SET_CM_FLUENCY_AUDIO_URL":
      return {
        ...state,
        cmfluencyAudioUrl: action.data,
      };
    case "SET_CM_FLUENCY_AUDIO_ERROR":
      return {
        ...state,
        audioError: action.data,
      };
    case "SET_CM_ROCKET_LOADER":
      return {
        ...state,
        cmfluencyRocketLoader: action.data,
      };
    case "SET_CM_FLUENCY_RESULT":
      console.log("setting values in reducer as ...", action);
      return {
        ...state,
        cmfluencyResult: action.data.fluencyData,
        isSubmitted: action.data.isSubmitted,
        error: action.data.error,
        cmfluencyRocketLoader: false, // TODO to be removed
        isCongratulationModal: action.data.isCongratulationModal,
        audioError: action.data.audioError ? action.data.audioError : "",
      };
    case "SET_CM_CONGRATULATION_MODAL":
      return {
        ...state,
        isCongratulationModal: action.data,
      };
    case "SET_RC_TEST_SCORE":
      return {
        ...state,
        rcScore: action.data,
      };
    case "SET_REPORT_DATA":
      return {
        ...state,
        reportData: action.data,
      };
    case "CLEAR_REPORT_DATA":
      return {
        ...state,
        reportData: {},
      };
    case "SET_CM_FLUENCY_VIEW_ID":
      return {
        ...state,
        cmFluencyViewID: action.data,
      };
    case "CLEAR_CM_FLUENCY_VIEW_ID":
      return {
        ...state,
        cmFluencyViewID: null,
      };
    default:
      return state;
  }
};
