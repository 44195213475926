import React from "react";
import { Modal } from "antd";
import "./index.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as studentActions from "../../store/student/actions";
import { CrossIcon } from "../../assets/image/svg";

function renderDate(date) {
  const parsedDate = new Date(date * 1000);
  return `${parsedDate.getDate()}/${
    parsedDate.getMonth() + 1
  }/${parsedDate.getFullYear()}`;
}

const TestsModal = (props) => {
  const announcementCards = props?.tests?.list?.map((test) => {
    return (
      <section className="post-heading">
        <div className={"announcement-content"}>
          <div className="media m-0 p-0">
            <div className="media-body mediaBody ml-0 p-0">
              <div className="row">
                <div className="col-6">
                <button className="event-btn">{test.tag}</button>
                
                </div>
                <div className="col-6">
                 <h6 className="announcement-date">
                  {renderDate(test?.created_at)}
                </h6>
                </div>
               
              </div>
              <h4 className="media-heading mb-0 ml-0 text-left announcement-heading">
                {test.name}
              </h4>
            </div>
          </div>
          <div className="post-body postBodyRight">
            {test.description !== "" && (
              <div className="desc">{test?.description}</div>
            )}
          </div>
          <div>
            <button
              onClick={() => window.open(test?.test_link, "_blank")}
              className="attend-btn"
            >
              ATTEND
            </button>
          </div>
        </div>
      </section>
    );
  });

  return (
    <Modal
      wrapClassName="announcement-modal"
      maskStyle={{
        background: "rgba(0,0,0,0.45)",
      }}
      title="Tests"
      width={350}
      visible={props.visible}
      onOk={null}
      onCancel={props.closeModal}
      okText="Submit"
      closeIcon={
        <i
          aria-label="icon: close"
          class="anticon anticon-close ant-modal-close-icon"
        >
          <CrossIcon />
        </i>
      }
    >
      <div className="children-cards d-flex wrap">{announcementCards}</div>
    </Modal>
  );
};
export default connect(
  ({ tests }) => ({ tests }),
  (dispatch) => bindActionCreators({ ...studentActions }, dispatch)
)(TestsModal);
