import React from "react";
import { Modal } from "antd";
import "./index.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as studentActions from "../../store/student/actions";
import { CrossIcon } from "../../assets/image/svg";
import { BrowserView, MobileView } from "react-device-detect";
import * as EVENT from "../../utils/eventKeys";

function renderDate(date) {
  const parsedDate = new Date(date);
  return `${parsedDate.getDate()}/${
    parsedDate.getMonth() + 1
  }/${parsedDate.getFullYear()}`;
}

const WorksheetModal = (props) => {
  const announcementCards = props.reduxData?.worksheets?.worksheets?.data?.map(
    (worksheet) => {
      return (
        <section className="post-heading pb-3 pl-4 pr-4 mb-3 position-relative announcement-row ht-lmt-all desc-hw">
          <div className="row">
            <div className="col-6">
              <div className="media m-0 p-0">
                <div className="media-body mediaBody ml-0 p-0">
                  <div className="d-flex j-c-sb">
                    <button className="hide-btn"></button>
                  </div>
                  <h4 className="media-heading mb-0 ml-0 text-left announcement-heading">
                    {worksheet?.worksheet?.title}
                  </h4>
                  <h4 className="media-heading mb-0 ml-0 text-left announcement-heading">
                    {worksheet?.worksheet?.description}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <span className="hr-line" />
        </section>
      );
    }
  );

  return (
    <Modal
      wrapClassName="announcement-modal"
      maskStyle={{
        background: "rgba(0,0,0,0.45)",
      }}
      title="Worksheets"
      width={350}
      visible={props.visible}
      onOk={null}
      onCancel={props.closeModal}
      okText="Submit"
      closeIcon={
        <i
          aria-label="icon: close"
          class="anticon anticon-close ant-modal-close-icon"
        >
          <CrossIcon />
        </i>
      }
    >
      <div className="children-cards d-flex wrap">{announcementCards}</div>
    </Modal>
  );
};
export default connect(
  ({ announcements }) => ({ announcements }),
  (dispatch) => bindActionCreators({ ...studentActions }, dispatch)
)(WorksheetModal);
