import { mutation } from "./mutations";
import { Home } from 'services/home';

const handleError = err => {
  console.log("Error in student action : ", err);
  return err.response || err;
};

// Actions
export const getFeeds = (query) => async dispatch => {
  try {
    const result = await Home.getFeed(query);
    dispatch(mutation.setFeedResult(result?.result || []));
    return result;
  } catch (err) {
    handleError(err);
  }
}
export const bookmarkFeed = (section, story) => async (dispatch) => {
  try {
    dispatch(mutation.updateBookmarkedFeed(section, story));
    return true;
  } catch (err) {
    handleError(err);
  }
};


export const setStartSessionTime = () => async (dispatch) => {
  try {
    dispatch(mutation.setStartSessionTime(new Date()));
    return true;
  } catch (err) {
    handleError(err);
  }
}


export const callSessionSet = (sessionOb) => async (dispatch) => {
  try {
    const result = await Home.callSessionSet(sessionOb);
    dispatch(mutation.setStartSessionTime());
    return true;
  } catch (err) {
    handleError(err);
  }
}  