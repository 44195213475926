import React, {useState} from "react";
import "./index.scss";
import {Tab, Tabs} from "react-bootstrap-tabs";
import {Accordion} from "react-bootstrap";
import { Analytics } from "../../services/analytics";
import * as EVENT from "../../utils/eventKeys";
import { storage as LocalStorage } from "services/config/storage";


export const TabList = ({firstTabLabel, secondTabLabel, firstTabData, secondTabData, isMobileView, onTabTwoViewAll, onTabOneViewAll, ...props}) => {
    const [activeTab, setActiveTab] = useState('')

    const setClassType = async (activeKey) => {
        setActiveTab(activeKey);
        //FREAD-1342
        if(activeKey==1)
        {
        let payload = {};
        payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
        payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level;
        console.log('Test Tab Event',EVENT.FLIVE_TEST_TAB, payload)
        Analytics.logEvents(EVENT.FLIVE_TEST_TAB, payload);
        }
        //FREAD-1342
    }
    return (
        <div className={'tabList tab-container-list'}>
            <Tabs activeKey={activeTab}
                  onSelect={setClassType} defaultActiveKey={activeTab}
                  className="tab-box">
                <Tab tabClassName={'upcoming-tab'} label={firstTabLabel}>
                    <div className="tab-1">
                        <Accordion defaultActiveKey="1">
                            {firstTabData}
                            {!isMobileView &&
                            <>
                                <div
                                //FREAD-1342
                                id="HW_VIEW_ALL"
                                //FREAD-1342
                                    className={'blur-container'}
                                    //FREAD-1324
                                    //onClick={onTabOneViewAll}
                                    onClick={
                                        ()=>
                                        {
                                            sendEvent(EVENT.FLIVE_HOMEWORK_VIEW_ALL);
                                            onTabOneViewAll();
    
                                        }}
                                    //FREAD-1324
                                />
                                <span
                                //FREAD-1342
                                id="HW_VIEW_ALL"
                                //FREAD-1342
                                 onClick={
                                    ()=>
                                    {
                                        sendEvent(EVENT.FLIVE_HOMEWORK_VIEW_ALL);
                                        onTabOneViewAll();

                                    }} 
                                //FREAD-1342
                                className={'view-all'}>View All</span>
                            </>
                            }
                        </Accordion>
                    </div>

                </Tab>
                <Tab tabClassName={'previous-tab'} label={secondTabLabel}>
                    <div className="tab-2 tab-pane">
                        <Accordion defaultActiveKey="0">
                            {secondTabData}
                            {!isMobileView &&
                            <>
                                <div
                                    className={'blur-container'}
                                    onClick={onTabTwoViewAll}
                                />
                                <span onClick={onTabTwoViewAll} className={'view-all'}>View All</span>
                            </>
                            }
                        </Accordion>
                    </div>
                </Tab>
            </Tabs>
        </div>
    );

}
//FREAD-1324
const sendEvent = (event) =>{
    let payload = {};
    payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
    payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level;
    console.log('hOMEwORK VIEW ALL',event, payload)
    Analytics.logEvents(event, payload);
}
//FREAD-1324
