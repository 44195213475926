import React from "react";
import "./index.scss";
import { BrowserView, MobileView } from "react-device-detect";

export const AnnouncementsList = ({ data, openAnnouncementsModal }) => {
  function renderDate1(date) {
    const parsedDate = new Date(date);
    return `${parsedDate.getDate()}/${
      parsedDate.getMonth() + 1
    }/${parsedDate.getFullYear()}`;
  }

  return data?.slice(0, 3)?.map((announcement) => {
    console.log("announcement", announcement);
    return (
      <section className="post-heading pb-3 pl-4 pr-4 mb-3 position-relative announcement-row ht-lmt-am">
        <div className="media m-0 p-0">
          <div className="media-body mediaBody ml-0 p-0">
            <div className="d-flex j-c-sb">
              <h4 className="media-heading mb-0 ml-0 text-left announcement-heading">
              {announcement.name}
            </h4>

              <h6 className="announcement-date">
                {renderDate1(announcement.created_at)}
              </h6>
            </div>
            
          </div>
        </div>

        <div className="post-body postBodyRight">
          <div className="row">
            <div className="col-7">
              {announcement.description !== "" && (
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{ __html: announcement.homework }}
                />
              )}
            </div>
            <div className="col-5">
              <div>
                <button onClick={openAnnouncementsModal} className="view-btn">
                  View more
                </button>
                <BrowserView>
                  {announcement?.homework_links &&
                    announcement?.homework_links?.map((link) => [
                      <div>
                        {link.includes("web.getfreadom.com") && (
                          <a className="btn-link" target="_blank" href={link}>
                            <button className=" ant-btn primary-btn  ant-btn-primary list-btn ">
                              GO TO HOMEWORK
                            </button>
                          </a>
                        )}
                      </div>,
                    ])}
                </BrowserView>

                <MobileView>
                  {announcement?.homework_links &&
                    announcement?.homework_links?.map((link) => [
                      <div>
                        {link.includes("app.getfreadom.com") && (
                          <a className="btn-link" target="_blank" href={link}>
                            <button className=" ant-btn primary-btn  ant-btn-primary list-btn ">
                              GO TO HOMEWORK
                            </button>
                          </a>
                        )}
                      </div>,
                    ])}
                </MobileView>
              </div>
            </div>
          </div>
        </div>

        <span className="hr-line" />
      </section>
    );
  });
};
const renderDate = (date) => {
  const parsedDate = new Date(date * 1000);
  return `${parsedDate.getDate()}/${
    parsedDate.getMonth() + 1
  }/${parsedDate.getFullYear()}`;
};
