import React from "react";
import { Modal } from "antd";
import "./index.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as studentActions from "../../store/student/actions";
import { CrossIcon } from "../../assets/image/svg";
import { BrowserView, MobileView } from "react-device-detect";
import * as EVENT from "../../utils/eventKeys";

function renderDate(date) {
  const parsedDate = new Date(date);
  return `${parsedDate.getDate()}/${
    parsedDate.getMonth() + 1
  }/${parsedDate.getFullYear()}`;
}

const AnnouncementModal = (props) => {
  const announcementCards = props.reduxData?.homeworks?.homeworks?.data?.map(
    (announcement) => {
      return (
        <section className="post-heading pb-3 pl-4 pr-4 mb-3 position-relative announcement-row ht-lmt-all desc-hw">
          <div className="row">
            <div className="col-6">
              <div className="media m-0 p-0">
                <div className="media-body mediaBody ml-0 p-0">
                  <div className="d-flex j-c-sb">
                    <button className="hide-btn"></button>
                  </div>
                  <h4 className="media-heading mb-0 ml-0 text-left announcement-heading">
                    {announcement.name}
                  </h4>
                </div>
              </div>
              <div className="post-body postBodyRight">
                {announcement.description !== "" && (
                  <div
                    className="desc1"
                    dangerouslySetInnerHTML={{ __html: announcement.homework }}
                  />
                )}
              </div>
            </div>

            <div className="col-6">
              <h6 className="announcement-date">
                {renderDate(announcement.created_at)}
              </h6>
              <BrowserView>
                {announcement?.homework_links &&
                  announcement?.homework_links?.map((link) => [
                    <div>
                      {link.includes("web.getfreadom.com") && (
                        <a
                          //FREAD-1342
                          id="FLV_HW_SECTION_BTN"
                          onClick={() =>
                            props.sendCtEvents(EVENT.FLIVE_GO_TO_HOMEWORK)
                          }
                          //FREAD-1342
                          className="btn-link"
                          target="_blank"
                          href={link}
                        >
                          <button className=" ant-btn primary-btn banner-btn ant-btn-primary ant-btn-lg homework-button">
                            GO TO HOMEWORK
                          </button>
                        </a>
                      )}
                    </div>,
                  ])}
              </BrowserView>

              <MobileView>
                {announcement?.homework_links &&
                  announcement?.homework_links?.map((link) => [
                    <div>
                      {link.includes("app.getfreadom.com") && (
                        <a
                          //FREAD-1342
                          id="FLV_HW_SECTION_BTN"
                          onClick={() =>
                            props.sendCtEvents(EVENT.FLIVE_GO_TO_HOMEWORK)
                          }
                          //FREAD-1342
                          className="btn-link"
                          target="_blank"
                          href={link}
                        >
                          <button className=" ant-btn primary-btn banner-btn ant-btn-primary ant-btn-lg homework-button">
                            GO TO HOMEWORK
                          </button>
                        </a>
                      )}
                    </div>,
                  ])}
              </MobileView>
            </div>
          </div>

          <span className="hr-line" />
        </section>
      );
    }
  );

  return (
    <Modal
      wrapClassName="announcement-modal"
      maskStyle={{
        background: "rgba(0,0,0,0.45)",
      }}
      title="Homeworks"
      width={350}
      visible={props.visible}
      onOk={null}
      onCancel={props.closeModal}
      okText="Submit"
      closeIcon={
        <i
          aria-label="icon: close"
          class="anticon anticon-close ant-modal-close-icon"
        >
          <CrossIcon />
        </i>
      }
    >
      <div className="children-cards d-flex wrap">{announcementCards}</div>
    </Modal>
  );
};
export default connect(
  ({ announcements }) => ({ announcements }),
  (dispatch) => bindActionCreators({ ...studentActions }, dispatch)
)(AnnouncementModal);
