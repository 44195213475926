import {initialState} from "./state";

export const teacher = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SEARCH_RESULT':
            return {
                ...state,
                searchResult: action.data
            } 
        case 'SET_LEVELS':
            return {
                ...state,
                levels: action.data
            }  
        default:
            return state;
    }
};
