import React, { Component } from "react";
import FormField from "../../../../../components/ProfileForm/FormField/index";
import cloneDeep from "lodash/cloneDeep";
import { Button, Col, Form, Input, Row } from "antd";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";

const FORM_TYPE_MOTHER = 1;
const FORM_TYPE_FATHER = 2;

class ParentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentFormFields: {
        fatherFormFields: props.formValues.fatherFormFields,
        motherFormFields: props.formValues.motherFormFields,
      },
    };
  }

  componentDidMount() {
    let newFatherFields = null;
    let newMotherDetails = null;
    // Logic to display father form if details are available
    if (!this.props.formValues.fatherFormFields) {
      let displayForm = false;
      newFatherFields = null;
      Object.keys(this.props.fatherDetails).forEach((element) => {
        if (this.props.fatherDetails[element].trim() != "") {
          displayForm = true;
        }
      });
      if (displayForm) {
        newFatherFields = cloneDeep(parentFormFields.fatherFormFields);
        newFatherFields[0].value = this.props.fatherDetails.name;
        newFatherFields[1].value = this.props.fatherDetails.employer_name;
        newFatherFields[2].value = this.props.fatherDetails.employer_address;
        newFatherFields[3].value = this.props.fatherDetails.salary_bracket;
      }
    } else this.handleFormValidity();

    if (!this.props.formValues.motherFormFields) {
      //Logic to display mother details if they are available
      newMotherDetails = null;
      let displayForm = false;
      Object.keys(this.props.motherDetails).forEach((element) => {
        if (this.props.motherDetails[element].trim() != "") {
          displayForm = true;
        }
      });
      if (displayForm) {
        newMotherDetails = cloneDeep(parentFormFields.motherFormFields);
        newMotherDetails[0].value = this.props.motherDetails.name;
        newMotherDetails[1].value = this.props.motherDetails.employer_name;
        newMotherDetails[2].value = this.props.motherDetails.employer_address;
        newMotherDetails[3].value = this.props.motherDetails.salary_bracket;
      }
    } else {
      this.handleFormValidity();
    }
    let context = this;
    this.setState(
      {
        parentFormFields: {
          ...this.state.parentFormFields,
          fatherFormFields:
            newFatherFields || this.props.formValues.fatherFormFields,
          motherFormFields:
            newMotherDetails || this.props.formValues.motherFormFields,
        },
      },
      () => {
        //set form values
        context.setFormFields();
        context.handleFormValidity();
      }
    );
  }

  setFormFields = () => {
    if (this.state.parentFormFields?.fatherFormFields?.length > 0)
      this.state.parentFormFields.fatherFormFields.forEach((item, index) => {
        this.props.form.setFieldsValue({ [item.name]: item.value });
      });
    if (this.state.parentFormFields?.motherFormFields?.length > 0)
      this.state.parentFormFields.motherFormFields.forEach((item, index) => {
        this.props.form.setFieldsValue({ [item.name]: item.value });
      });
  };

  handleFormValidity() {
    let isValid = true;
    const namePattern = /^[a-zA-Z\s]+$/i;
    if (
      !this.state.parentFormFields.fatherFormFields &&
      !this.state.parentFormFields.motherFormFields
    ) {
      return this.props.onFormValidChange(false);
    }
    if (this.state.parentFormFields.fatherFormFields) {
      this.state.parentFormFields.fatherFormFields.forEach((element) => {
        if (
          element.value == null ||
          (typeof element.value == "string" && element.value.trim() == "")
        ) {
          isValid = false;
        }
      });

      if (
        !this.state.parentFormFields.fatherFormFields[0].value.match(
          namePattern
        )
      ) {
        isValid = false;
      }
    }
    if (this.state.parentFormFields.motherFormFields) {
      this.state.parentFormFields.motherFormFields.forEach((element) => {
        if (
          element.value == null ||
          (typeof element.value == "string" && element.value.trim() == "")
        ) {
          isValid = false;
        }
      });
      if (
        !this.state.parentFormFields.motherFormFields[0].value.match(
          namePattern
        )
      ) {
        isValid = false;
      }
    }
    this.props.onFormValidChange(isValid);
  }

  getFormFields = () => cloneDeep(this.state.parentFormFields);

  onValueChange(event, index, formType) {
    let formKey =
      formType == FORM_TYPE_MOTHER ? "motherFormFields" : "fatherFormFields";
    let newParentFormFields = cloneDeep(this.state.parentFormFields);
    newParentFormFields[formKey][index].value = event.target
      ? event.target.value
      : event;
    this.setState(
      {
        parentFormFields: {
          ...newParentFormFields,
        },
      },
      () => {
        this.handleFormValidity();
      }
    );
  }

  isValidValue = (element, value) => {
    let allowUpdate = true;
    if (element?.regex && value !== "" && !value.match(element.regex))
      allowUpdate = false;
    return allowUpdate;
  };

  getFieldComponents = (formType, getFieldDecorator) => {
    let data =
      formType == FORM_TYPE_MOTHER
        ? this.state.parentFormFields.motherFormFields
        : this.state.parentFormFields.fatherFormFields;
    return data?.map((field, index) => (
      <FormField
        {...field}
        getFieldDecorator={getFieldDecorator}
        onValueChange={(event) => {
          if (this.isValidValue(field, event?.target?.value)) {
            this.onValueChange(event, index, formType);
          }
        }}
        fieldType={field.type}
      />
    ));
  };

  renderParentForm(formType) {
    const { getFieldDecorator } = this.props.form;
    let fieldComponents = this.getFieldComponents(formType, getFieldDecorator);
    return (
      <React.Fragment>
        <div className="mobile-border">
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1
              style={{ padding: "0px", fontSize: "20px" }}
              className="student-profile-title"
            >
              {formType == FORM_TYPE_MOTHER
                ? "Mother's Details"
                : "Father's Details"}
            </h1>
            <Button
              className="close-btn"
              onClick={() => this.onCloseForm(formType)}
            >
              X
            </Button>
          </div>
          <Input.Group>
            <Row>
              <Col span={11}>{fieldComponents[0]}</Col>
              <Col span={1} />
              <Col span={11}>{fieldComponents[1]}</Col>
            </Row>
            <Row>{fieldComponents[2]}</Row>
            <Row>
              <Col span={11}>{fieldComponents[3]}</Col>
            </Row>
          </Input.Group>
        </div>
      </React.Fragment>
    );
  }

  onDisplayForm(formType) {
    if (formType == FORM_TYPE_FATHER) {
      this.setState(
        {
          ...this.state,
          parentFormFields: {
            ...this.state.parentFormFields,
            fatherFormFields: parentFormFields.fatherFormFields,
          },
        },
        () => {
          this.handleFormValidity();
        }
      );
    } else {
      this.setState(
        {
          ...this.state,
          parentFormFields: {
            ...this.state.parentFormFields,
            motherFormFields: parentFormFields.motherFormFields,
          },
        },
        () => {
          this.handleFormValidity();
        }
      );
    }
  }

  onCloseForm(formType) {
    switch (formType) {
      case FORM_TYPE_MOTHER:
        this.setState(
          {
            ...this.state,
            parentFormFields: {
              ...this.state.parentFormFields,
              motherFormFields: null,
            },
          },
          () => {
            this.handleFormValidity();
          }
        );
        break;
      case FORM_TYPE_FATHER:
        this.setState(
          {
            ...this.state,
            parentFormFields: {
              ...this.state.parentFormFields,
              fatherFormFields: null,
            },
          },
          () => {
            this.handleFormValidity();
          }
        );
        break;
    }
  }

  isFormValid = (e) => {
    e && e.preventDefault();
    let isFormValid = false;
    this.props.form.validateFields(async (err, values) => {
      if (!err) isFormValid = true;
    });
    return isFormValid;
  };

  render() {
    let fields = this.state.parentFormFields.motherFormFields;
    const motherForm = this.state.parentFormFields.motherFormFields
      ? this.renderParentForm(FORM_TYPE_MOTHER)
      : null;
    const fatherForm = this.state.parentFormFields.fatherFormFields
      ? this.renderParentForm(FORM_TYPE_FATHER)
      : null;

    return (
      <div>
        <Form
          onSubmit={this.isFormValid}
          name="parent-form"
          initialValues={{ remember: true }}
          className="custom-form"
        >
          <div className="parent-form-fields form-fields">
            {fatherForm ? (
              fatherForm
            ) : (
              <Button
                className="flat-btn"
                style={{ display: "block" }}
                onClick={() => this.onDisplayForm(FORM_TYPE_FATHER)}
              >
                + Add father's details
              </Button>
            )}
            {motherForm ? (
              motherForm
            ) : (
              <Button
                className="flat-btn "
                onClick={() => this.onDisplayForm(FORM_TYPE_MOTHER)}
              >
                + Add mother's details
              </Button>
            )}
          </div>
        </Form>
      </div>
    );
  }
}

const parentFormFields = {
  motherFormFields: [
    {
      title: "MOTHER'S NAME",
      name: "mother-name",
      placeholder: "Child's mother's name",
      value: "",
      type: "text",
      error: "This cannot be empty",
      className: "",
      regex: /^[a-zA-Z\s]+$/,
      regexErrorMessage: "Only alphabets allowed",
    },
    {
      title: "EMPLOYER'S NAME",
      name: "mother-employer-name",
      placeholder: "Where the mother works",
      value: "",
      type: "text",
      className: "",
      error: "This cannot be empty",
      regex: /^[a-zA-Z\s]+$/,
      regexErrorMessage: "Only alphabets allowed",
    },
    {
      title: "EMPLOYER ADDRESS",
      name: "mother-employer-address",
      placeholder: "Work address",
      value: "",
      type: "textarea",
      error: "This cannot be empty",
      className: "",
    },
    {
      title: "SALARY BRACKET",
      name: "mother-salary",
      placeholder: "Select One",
      value: "",
      type: "dropdown",
      error: "This cannot be empty",
      className: "",
      options: [
        {
          title: "0-6 LPA",
          value: "0-6 LPA",
        },
        {
          title: "6-10 LPA",
          value: "6-10 LPA",
        },
        {
          title: "10+ LPA",
          value: "10+ LPA",
        },
      ],
    },
  ],
  fatherFormFields: [
    {
      title: "FATHER'S NAME",
      name: "father-name",
      placeholder: "Child's father's name",
      value: "",
      error: "This cannot be empty",
      type: "text",
      className: "",
      regex: /^[a-zA-Z\s]+$/,
      regexErrorMessage: "Only alphabets allowed",
    },
    {
      title: "EMPLOYER'S NAME",
      name: "father-employer-name",
      placeholder: "Where the father works",
      value: "",
      type: "text",
      className: "",
      error: "This cannot be empty",
      regex: /^[a-zA-Z\s]+$/,
      regexErrorMessage: "Only alphabets allowed",
    },
    {
      title: "EMPLOYER ADDRESS",
      name: "father-employer-address",
      placeholder: "Work address",
      value: "",
      type: "textarea",
      className: "",
      error: "This cannot be empty",
    },
    {
      title: "SALARY BRACKET",
      name: "father-salary",
      placeholder: "Select One",
      value: "",
      type: "dropdown",
      error: "This cannot be empty",
      className: "",
      options: [
        {
          title: "0-6 LPA",
          value: "0-6 LPA",
        },
        {
          title: "6-10 LPA",
          value: "6-10 LPA",
        },
        {
          title: "10+ LPA",
          value: "10+ LPA",
        },
      ],
    },
  ],
};

export default Form.create({ name: "parent-form" })(ParentForm);
