import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { user } from "./user/reducer";
import { pricing } from "./pricing/reducer";
import { collections } from "./collections/reducer";
import { story } from "./story/reducer";
import { activities } from "./activities/reducer";
import { guidedPath } from "./guidedPath/reducer";
import { news } from "./news/reducer";
import { student } from "./student/reducer";
import { attendance } from "./attendance/reducer";
import { subscriptions } from "./subscriptions/reducer";
import { announcements } from "./announcements/reducer";
import { tests } from "./tests/reducer";
import { reports } from "./reports/reducer";
import { teacher } from "./teacher/reducer";
import { home } from "./home/reducer";
import { detailedStory } from "./story/detailedStoryreducer";
import { appConfig } from "./appConfig/reducer";
import { quizes } from "./quiz/reducer";
import { persistReducer } from "redux-persist";
import { homeworks } from "./Homework/reducer";
import { worksheets } from "./worksheet/reducer";
import { cmfluency } from "./cmfluency/reducer";
import { linkschoolcode } from "./LinkSchoolCode/reducer";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
const persistConfig = {
  key: "freadom",
  storage,
  blacklist: ["student", "announcements"],
};

const studentPersistConfig = {
  key: "student",
  storage: storage,
  blacklist: ["classes"],
};

export const rootReducer = {
  user,
  pricing,
  collections,
  story,
  activities,
  guidedPath,
  news,
  attendance,
  subscriptions,
  announcements,
  teacher,
  home,
  appConfig,
  quizes,
  tests,
  reports,
  detailedStory,
  homeworks,
  worksheets,
  cmfluency,
  linkschoolcode,
};
const devTool =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : compose
    : compose;

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    ...rootReducer,
    student: persistReducer(studentPersistConfig, student),
  })
);

const rootReducer1 = (state, action) => {
  if (action.type === "RESET_STATE") {
    state = undefined;
  }
  return persistedReducer(state, action);
};

const store = createStore(
  rootReducer1,
  compose(applyMiddleware(thunkMiddleware), devTool)
);

export default store;
