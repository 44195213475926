import { Api } from "./config/request";
import { storage as LocalStorage } from "services/config/storage";

export const getNewsList = (offset) =>
  Api.getRequest(
    `news-fread/v1/?grade_id=${
      LocalStorage.fetch.defaultChild()?.grade.id
    }&all_news=True&offset=${offset}`
  );

export const getNewsDetail = (id) => Api.getRequest(`news-fread/v1/${id}/`);

export const submitNewsQuizAnswer = (payload) =>
  Api.postRequest("news-fread-assessment/v1/answer/", payload);

export const getNewsMcq = (id) =>
  Api.getRequest(`news-fread-assessment/v1/news_fread/${id}/question/`);

export const createStreak = (payload) => Api.postRequest('user/v1/streak/', payload);

export const newsServices = {
  getNewsList,
  getNewsDetail,
  getNewsMcq,
  submitNewsQuizAnswer,
  createStreak,
};
