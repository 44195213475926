import { initialState } from "./state";

export const user = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_DETAIL": {
      return Object.assign({}, state, {
        userDetail: action.data,
      });
    }
    case "SET_USER_AUTH_TOKEN": {
      return {
        ...state,
        authToken: action.data
      }
    }
    case "SET_DEFAULT_CHILD": {
      return {
        ...state,
        defaultChildData: action.data
      }
    }
    case "SET_CHILD_LIST": {
      return {
        ...state,
        childList: action.data
      }
    }
    case "SET_BATCH_ID": {
      return {
        ...state,
        selectedBatchId: action.data
      }
    }
    case "SET_CHILD_GRADES": {
      return {
        ...state,
        gradeList: action.data
      }
    }
	//FLV-113
    case "SET_NAVBAR_FLAG": {
      return {
        ...state,
        navbarFlag: action.data
      }
    }
	//FLV-113
    default:
      return state;
  }
};
